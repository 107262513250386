var ns;
ns.App.factory('DataDownloadService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils
  ){
  return {
    getTargetData: function(){
      var endpoint = API_ENDPOINT + '/data_download';
      return HttpFactory.createGet(endpoint);
    },
    getArHeadersData: function(sid, params){
      var endpoint = API_ENDPOINT + '/ar_headers?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    }
  };
});

