ns.App.factory('UnitsService', function ($http, API_ENDPOINT, Utils, HttpFactory) {
  return {
    getUnits: function () {
      return $http.get(API_ENDPOINT + '/units/available');
    },
    getUnit: function(sid, id){
      var endpoint = API_ENDPOINT + '/units/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getCurrentUnits: function (sid, params) {
      var endpoint = API_ENDPOINT + '/units/current?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getUnitInfo: function () {
    var endpoint = API_ENDPOINT + '/units/info';
    return HttpFactory.createGet(endpoint);
  },
    getUnitReports: function (params) {
      var endpoint = API_ENDPOINT + '/units/available/reports?';
      if(!angular.isUndefined(params)){
        endpoint += Utils.getQueryStr(params);
      }
      return $http.get(endpoint);
    },
    getUnitInterface: function (params) {
      var endpoint = API_ENDPOINT + '/units/available/interfaces?';
      if(!angular.isUndefined(params)){
        endpoint += Utils.getQueryStr(params);
      }
      return $http.get(endpoint);
    },
    getUnitShipmentReceipt: function (params) {
      var endpoint = API_ENDPOINT + '/units/available/sh_receipt?';
      if(!angular.isUndefined(params)){
        endpoint += Utils.getQueryStr(params);
      }
      return $http.get(endpoint);
    },

    //FN_70010 基本設定登録
    //ユニット登録
    storeForUnit: function(params){
      var endpoint = API_ENDPOINT + '/units/available';
      return HttpFactory.createPost(endpoint, params);
    },
    //ユニット更新
    updateForUnit: function(params, id){
      var endpoint = API_ENDPOINT + '/units/available/' + id;
      return HttpFactory.createPut(endpoint, params);
    }
  };
});

