var ns;
ns.App.factory('Utils', function(){
  return {
    getPastDateObj: function (diff) {
      var tmp = new Date();
      tmp.setDate(tmp.getDate() - diff);
      return tmp;
    },
    getDateDiff: function (date1, date2) {
      return (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 *24);
    },
    getDateStr : function (date) {
      if(typeof date === "object"){
        if(date === null){
          return null;
        }else{
          return date.getFullYear() + "-" + ("0" + (date.getMonth()+1)).slice(-2) + "-" + ('00' + date.getDate()).slice(-2);
        }
      }else{
        return date;
      }
    },
    getDateTimeStr: function (date) {
      if(typeof date === "object") {
        if(date === null){
          return null;
        }else{
          return date.getUTCFullYear() + '-' +
          ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
          ('00' + date.getUTCDate()).slice(-2) + ' ' + 
          ('00' + date.getUTCHours()).slice(-2) + ':' + 
          ('00' + date.getUTCMinutes()).slice(-2) + ':' + 
          ('00' + date.getUTCSeconds()).slice(-2);
        }
      } else {
        return date;
      }
    },
    getParam : function (params, attr) {
      if (attr in params){
        return params[attr];
      }else{
        return "";
      }
    },
    getQueryStr : function (queryObj) {
      var tail = [];
      angular.forEach(queryObj, function (value, key) {
        if (angular.isArray(value)) {
          if (0 < value.length) {
            angular.forEach(value, function (value2/*, key2*/) {
              if (!angular.isUndefined(value2)) {
                tail.push(key + "=" + encodeURIComponent(value2));
              }
            });
          }
        } else {
          if (!angular.isUndefined(value)) {
            tail.push(key + "=" + encodeURIComponent(value));
          }
        }
      });
      return tail.join("&");
    }
  };
});

