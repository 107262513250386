var ns;
(function () {
  var SessionController = function ($scope, $location, $http, $window, $routeParams, $controller, SessionsService, ModalFactory, UnitsService) {
    $controller('BaseController', {$scope: $scope});

    document.getElementById('unittop').style.display="none";
    $scope.registClicked = false;
    $scope.entry = function () {
      $scope.registClicked = true;
      var checkedAttrs = [];
      for(var i = 0; i<$scope.kg.dataSource.data().length; i++){
        var item = $scope.kg.dataSource.at(i);
        if(item.checked){
          checkedAttrs.push(item.id);
        }
      }
      if(checkedAttrs.length == 0){
        ModalFactory.createIModal("E001");
        $scope.registClicked = false;
        return;
      }
      $scope.postEntry(checkedAttrs);
    };
    $scope.onUnitClick = function(dataItem){
      $scope.postEntry([dataItem.id]);
    };
    $scope.checkAllSelected = function () {
      var allChecked = true;
      for(var i = 0; i<$scope.kg.dataSource.data().length; i++){
        var item = $scope.kg.dataSource.at(i);
        if(!item.checked){
          allChecked = false;
          break;
        }
      }
      $scope._checkAll = allChecked;
      $('#checkAll').prop("checked", allChecked);
    };
    $scope.postEntry = function(checkedAttrs){
      SessionsService.postSessions(checkedAttrs)
      .success(function () {
        location.href = 'unittop';
      });
      $scope.registClicked = false;
    };
    $scope.SelectAll = function(ev) {
      var items = $scope.kg.dataSource.data();
      $scope._checkAll = ev.target.checked;
      items.forEach(function (item) {
        item.checked = ev.target.checked;
      });
    };

    $scope.gridOptions = {
      dataSource: {
        serverPaging: false,
        serverSorting: false,
        pageSize: 100,
        transport: {
          read: {
            url: 'api/v2/units/available',
            dataType: "json"
          }
        },

        schema: {
          model: {
            fields: {
              nm: {type: "string"},
              end_dttm: {type: "date"},
              dsc: {type: "string"},
              owners_nm: {type: "string"},
              warehouses_nm: {type: "string"},
              stt_dttm: {type: "date"}
            }
          }
        }
      },
        ignoreCase: false,
        filter: "gte",
        resizable: true,
        columnMenu: true,
        reorderable: true,
        scrollable: {
          virtual: true
        },
        height: $(document).height() - 230,
        sortable: {
          mode: "multiple",
          allowUnsort: true
        },
        pageable: true,
        filterable: {
          extra: true
        },
        schema: {
          model: {
            fields: {
              nm: {type: "string"},
              end_dttm: {type: "string"},
              dsc: {type: "string"},
              owners_nm: {type: "string"},
              warehouses_nm: {type: "string"},
              stt_dttm: {type: "date"}
            }
          }
        },
        columns: [
          {field: "check_row", title: "<input id='checkAll' ng-model='_checkAll' type='checkbox' title='Select all' ng-click='SelectAll($event)'/>",
            width: 50, sortable: false, filterable: false, locked: true, lockable: false, template: '<input ng-model = "dataItem.checked" type="checkbox" ng-change="checkAllSelected()" />' },
          {field: "nm", title: "ユニット", width: "200px",
            template: function (dataItem) {
              return "<div ng-click='onUnitClick(dataItem)'><a href='#'>" + dataItem.nm + "</a></div>";
            }
          },
          {field: "dsc", title: "ユニット説明", width: "200px"},
          {field: "end_dttm", title: "ステータス", width: "200px",
            template: function (dataItem) {
              if(!dataItem.end_dttm){
                value = "稼働中";
                color = "rgb(161, 234, 119)";
                fontcolor = "black";
              }
              else{
                value = "停止中";
                color = "gray";
                fontcolor = "white";
              }
              return "<div style='background-color:" + color + ";color:" + fontcolor + ";text-align:center;'>" + value + "</div>";
            }
          },
          {field: "owners_nm", title: "オーナー", width: "200px"},
          {field: "warehouses_nm", title: "システム倉庫", width: "200px"},
          {field: "stt_dttm", title: "利用開始日", width: "200px", template: "#= kendo.toString(kendo.parseDate(stt_dttm, 'yyyy-MM-dd'), 'yyyy/MM/dd') #"},
        ]
      };
    $scope.registOwner = function(isSysMgr){
      $scope.registClicked = true;
      if (isSysMgr) {
        location.href = '/owner';
      } else {
        ModalFactory.createIModal("E017");
        $scope.registClicked = false;
      }
    };
    $scope.registWarehouse = function(isSysMgr){
      $scope.registClicked = true;
      if (isSysMgr) {
        location.href = '/warehouse';
      } else {
        ModalFactory.createIModal("E017");
        $scope.registClicked = false;
      }
    };
    $scope.createUnit = function(isSysMgr){
      $scope.registClicked = true;
      if (isSysMgr) {
        location.href = '/unit';
      } else {
        ModalFactory.createIModal("E017");
        $scope.registClicked = false;
      }
    };
    $scope.userList = function(isSysMgr){
      $scope.registClicked = true;
      if (isSysMgr) {
        location.href = '/user';
      } else {
        ModalFactory.createIModal("E017");
        $scope.registClicked = false;
      }
    };
    $scope.contract = function(isSysMgr){
      $scope.registClicked = true;
      if (isSysMgr) {
        location.href = '/company';
      } else {
        ModalFactory.createIModal("E017");
        $scope.registClicked = false;
      }
    };
  };
  //add dependency
  SessionController.$inject = [
    '$scope', '$location', '$http', '$window', '$routeParams', '$controller', 'SessionsService', 'ModalFactory', 'UnitsService'
  ];
  ns.App.controller('SessionController', SessionController);


}());
