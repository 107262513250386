var ns = ns || {};
ns.App = angular.module(
  'w3App', 
  [
    'ngRoute', 'ngResource', "kendo.directives", "ngFileUpload", "ngAnimate", 'ngCookies'
  ], 
  function($routeProvider, $locationProvider, $httpProvider, CSRF_TOKEN, SYSTEM_NAME) {
    $routeProvider.
      when('/',{ controller: "SessionController", templateUrl: '/templates/top.index', title: SYSTEM_NAME + " | トップ画面"}).
      when('/login',{ controller: "SessionController", templateUrl: '/templates/top.login', title: SYSTEM_NAME + " | ログイン画面"}).
      when('/unittop',{ controller: "UnitTopController", templateUrl: '/templates/top.unittop', title: SYSTEM_NAME + " | ユニットトップ画面"}).
      otherwise({redirectTo: '/'});
  
  }
);

