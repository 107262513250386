var ns;
ns.App.factory('ArrivesService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils
  ){
  return {
    getArHeaders: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/ar_headers?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getArHeadersData: function(sid, params){
      var endpoint = API_ENDPOINT + '/ar_headers?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getArHeader: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/ar_headers/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    deleteArHeaders: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/batch_delete';
      if(!angular.isUndefined(params)){
        endpoint += "?" + Utils.getQueryStr(params);
      }
      return HttpFactory.createDelete(endpoint);
    },
    getArHeaderData: function(sid, id){
      var endpoint = API_ENDPOINT + '/ar_headers/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getArDetails: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/ar_details?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getArResultsByDetail: function(sid, dsConf, ar_details_id, params){
      var endpoint = API_ENDPOINT + '/ar_results?screen_id=' + sid + '&detail_ids[]=' + ar_details_id;
      if(!angular.isUndefined(params)){
        endpoint += "&" + params;
      }
      return DsFactory.create(dsConf, endpoint);
    },
    deleteArDetails: function(params){
      var endpoint = API_ENDPOINT + '/ar_details/batch_delete';
      if(!angular.isUndefined(params)){
        endpoint += "?" + Utils.getQueryStr(params);
      }
      return HttpFactory.createDelete(endpoint);
    },
    getArDetailsData: function(sid, params){
      var endpoint = API_ENDPOINT + '/ar_details';
      var prefix = "?";
      if(sid != null && !angular.isUndefined(sid)){
        endpoint += "?screen_id=" + sid;
        prefix = "&";
      }
      if(!angular.isUndefined(params)){
        endpoint += prefix + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getArDetailsWithInspectionData: function(sid, params){
      var endpoint = API_ENDPOINT + '/ar_details_with_inspections?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getArDetail: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/ar_details/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getArDetailData: function(sid, id){
      var endpoint = API_ENDPOINT + '/ar_details/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getArResults: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/ar_results?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getArResultsForResultDetails: function(sid, dsConf, ar_detail_id){
      var endpoint = API_ENDPOINT + '/ar_results?screen_id=' + sid + "&status=fixable&detail_ids[]=" + ar_detail_id;
      return DsFactory.create(dsConf, endpoint);
    },
    getArResultsData: function(sid, params){
      var endpoint = API_ENDPOINT + '/ar_results?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getArResult: function(sid, dsConf, id){
      var endpoint = API_ENDPOINT + '/ar_results/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getArWorkHistories: function(sid, dsConf, params){
      var endpoint = API_ENDPOINT + '/ar_work_histories?screen_id=' + sid;
      if(!angular.isUndefined(params)){
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getArWorkHistoriesByDetail: function(sid, dsConf, ar_details_id, params){
      var endpoint = API_ENDPOINT + '/ar_work_histories?screen_id=' + sid + '&detail_ids[]=' + ar_details_id;
      if(!angular.isUndefined(params)){
        endpoint += "&" + params;
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getProgressOverviewData: function(){
      var endpoint = API_ENDPOINT + '/ar_progress/overview';
      return HttpFactory.createGet(endpoint);
    },
    /**
    * 入荷API:検品結果の新規登録・更新
    */
    upsertInspections: function(params){
      var endpoint = API_ENDPOINT + '/ar_details/batch_upsert_inspections';
      return HttpFactory.createPost(endpoint, params);
    },
    /**
    * 入荷API:検品結果のキャンセル
    */
    cancelInspectionsForHeader: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/batch_cancel_inspections';
      return HttpFactory.createPost(endpoint, params);
    },
    cancelInspectionsForDetail: function(params){
      var endpoint = API_ENDPOINT + '/ar_details/batch_cancel_inspections';
      return HttpFactory.createPost(endpoint, params);
    },

    /**
    * 入荷API:実績の確定
    */
    fixInspectionsForHeader: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/batch_fix_inspections';
      return HttpFactory.createPost(endpoint, params);
    },
    fixInspectionsForDetail: function(params){
      var endpoint = API_ENDPOINT + '/ar_details/batch_fix_inspections';
      return HttpFactory.createPost(endpoint, params);
    },

    /**
    * 入荷API:実績の確定(Sanwa)
    */
    sanwaFixInspectionsForHeader: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/sanwa_batch_fix_inspections';
      return HttpFactory.createPost(endpoint, params);
    },
    sanwaFixInspectionsForDetail: function(params){
      var endpoint = API_ENDPOINT + '/ar_details/sanwa_batch_fix_inspections';
      return HttpFactory.createPost(endpoint, params);
    },

    /**
     * 入荷API:SOLAの箱番号ラベル発行
     */
    publishSolaBoxNoLabelForHeader: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/batch_publish_sola_box_no_label';
      return HttpFactory.createPost(endpoint, params);
    },

    /**
     * 入荷API:SOLAの箱番号ラベル発行
     */
    publishActusVasLabelForHeader: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/batch_publish_actus_vas_label';
      return HttpFactory.createPost(endpoint, params);
    },

    /**
    * 入荷API:入荷検品、その後、即実績確定
    */
    fixNowForHeader: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/batch_fix_now';
      return HttpFactory.createPost(endpoint, params);
    },
    fixNowForDetail: function(params){
      var endpoint = API_ENDPOINT + '/ar_details/batch_fix_now';
      return HttpFactory.createPost(endpoint, params);
    },

    /**
    * 入荷API:入荷の追加・変更
    */
    upsert: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/batch_store_or_update';
      return HttpFactory.createPost(endpoint, params);
    },
    /**
    * 入荷API:返品入荷登録
    */
    storeReturn: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/batch_store_return';
      return HttpFactory.createPost(endpoint, params);
    },
    /**
    * 入荷API:コピー
    */
    copy: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/batch_copy';
      return HttpFactory.createPost(endpoint, params);
    },
    /**
    * 入荷実績確定時の検品チェック
    */
    arHeadersCheckAllInspectionsNormal: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/check_all_inspections_normal';
      endpoint += "?" + Utils.getQueryStr(params);
      return HttpFactory.createGet(endpoint, false);
    },
    arDetailsCheckAllInspectionsNormal: function(params){
      var endpoint = API_ENDPOINT + '/ar_details/check_all_inspections_normal';
      endpoint += "?" + Utils.getQueryStr(params);
      return HttpFactory.createGet(endpoint, false);
    },

    /**
     * 入荷API:入荷予定ラベル発行
     */
    publishArScheduledLabelForHeader: function(params){
      var endpoint = API_ENDPOINT + '/ar_headers/batch_publish_ar_scheduled_labels';
      return HttpFactory.createPost(endpoint, params);
    },

    publishArScheduledLabelForDetail: function(params){
      var endpoint = API_ENDPOINT + '/ar_details/batch_publish_ar_scheduled_labels';
      return HttpFactory.createPost(endpoint, params);
    }
  };
});

