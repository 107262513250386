var ns, kendo;
(function () {
  var BaseController = function ($scope, $rootScope, $location, $window, $routeParams,
                                 $http, $route, $q, $interval, $filter, $document, $cookies, Upload, UnitsService,
                                 AuthService, ScreensService, MaterialsService,
                                 ClientsService, DeliveriesService, ShipmentsService,
                                 CarriersService, SuppliersService, LocationsService, FileIOService, UnitScreensService, ModalFactory,
                                 MAX_SELECTION)
  {
    //編集画面の詳細データキャッシュ用領域
    $scope._detailedData = {};
    //全画面表示フラグ
    $scope.showHeader = true;
    $scope.showSearchBox = false;

    //タイトル取得
    var title = $route.current.$$route.title;
    var expire = new Date("2030/01/01 0:00:00");


    AuthService.getVersion().success(function (res)
    {
      //バージョン情報の確認
      var sKey = 'version';
      var strCookie = $cookies.get(sKey);

      if (!strCookie) {
        $cookies.putObject(String(sKey), res.data.version, {
          expires: expire
        });
      }
      else {
        var obj = $cookies.getObject(sKey);
        if (obj != res.data.version) {
          $cookies.putObject(String(sKey), res.data.version, {
            expires: expire
          });
          $window.alert("最新のソースを読み込めていない可能性があります。最新のソースを取得します。");
          $window.location.reload(true);
        }
      }
    }).error(function (data)
    {
    });

    //マイサーチラベルの設定
    $scope.getMySearchLabel = function (index) {
      var label = "マイサーチ" + (index + 1);
      var sid = $scope._screenId;
      var strCookie = $cookies.get(sid);
      if (!strCookie) {
        return label;
      }
      else{
        var obj = $cookies.getObject(sid);
      }
      var conf = obj[index];
      if (angular.isUndefined(conf) ||
        angular.isUndefined(conf.label) ||
        conf.label === "") {
        return label;
      } else {
        return conf.label;
      }
    };
    $scope.$on('$viewContentLoaded', function () {
      $scope._mySearchLabel1 = $scope.getMySearchLabel(0);
      $scope._mySearchLabel2 = $scope.getMySearchLabel(1);
      $scope._mySearchLabel3 = $scope.getMySearchLabel(2);
    });
    angular.element(document.getElementsByTagName("title")[0]).html(title);
    //編集用タブ用の空メソッド
    $scope.voidfunc = function () {
      return {
        success: function (f) {
          f([{}])
        }
      }
    };

    $scope.canSelectUnits = UnitsService.getUnits().success(function (data) {
      return data;
    });

    //ログアウトメソッド
    $scope.logout = function () {
      AuthService.logout()
        .success(function () {
          $location.path("/");
          $route.reload();
          $window.location.reload(true);
        });
    };
    //レスポンスのOK判定
    $scope.isOK = function (response) {
      return response.status === "OK";
    };
    //レスポンスのNG判定
    $scope.isNG = function (response) {
      return response.status === "NG";
    };
    //レスポンスのNG判定
    $scope.isNG = function (response) {
      return response.status === "NG";
    };
    //レスポンスのWARN判定
    $scope.isWARN = function (response) {
      return response.status === "WARN";
    };
    //全画面表示切り替えメソッド
    $scope.toggleHeader = function () {
      $scope.showHeader = !$scope.showHeader;
      $scope.resizeGrid();
    };
    $scope.toggleSearchBox = function () {
      $scope.showSearchBox = !$scope.showSearchBox;
    };
    $scope.displaySetting = function (unitRollId, unitScreenId) {
      UnitScreensService.getUnitScreensCanDisplaySetting()
        .success(function (res) {
          if (res[0].is_display == true) {
            location.href = '/unit_grid_column_display_edits' + '?' + 'unit_roll_id=' + unitRollId + '&unit_screen_id=' + unitScreenId;
          } else {
            ModalFactory.createIModal("E017");
          }
        })
        .error(function (data) {
          ModalFactory.createEModalByServerData(data);
        });
    };

    //チェックしているgridの行を取得
    $scope.getChecked = function () {
      var grid = $("#grid").data("kendoGrid").dataSource.data();
      var checkedAttrs = [];
      for (var i = 0; i < grid.length; i++) {
        var item = grid.at(i);
        if (item.checked) {
          checkedAttrs.push(item.units_id);
        }
      }
      return checkedAttrs;
    };

    //abstract
    //詳細ボタン(各コントローラで実装)
    $scope.toDetails = function () {
      var checkedAttrs = $scope.getChecked();
    };
    //abstract
    //行毎の詳細ボタン(各コントローラで実装)
    $scope.toDetail = function (row) {
    };
    //abstract
    //業毎の一覧ボタン(各コントローラで実装)
    $scope.toHeader = function (row) {

    };
    //カラムセレクトイベント
    $scope.columnSelected = function onChange() {
      var selected = $.map(this.select(), function (item) {
        return $(item).text();
      });

    };
    //gridの行にツールボタンを追加
    $scope.addToolButton = function (columns) {
      columns.unshift({
        width: "42px", command: [
          {
            name: "詳細",
            template: '<img ng-show="_show_to_detail_button" ng-click="toDetail(dataItem)" class="clickable btn" style="padding:4px; display: block" src="/images/icon-meisai-s.png"/>'
          },
          {
            name: "一覧",
            template: '<img ng-show="_show_to_header_button" ng-click="toHeader(dataItem)" class="clickable btn" style="padding:4px; display: block" src="/images/icon-meisai-s.png"/>'
          }
        ]
      });
      columns.unshift({
        title: "<input id='checkAll' ng-model='_checkAll' type='checkbox' title='Select all' ng-click='SelectAll($event)'/>",
        width: "35px",
        command: [{
          name: "check_row",
          template: '<input ng-model="dataItem.checked" type="checkbox" ng-change="checkAllSelected()"/>'
        }]
      });
      angular.forEach(columns, function(value, key) {
          if(value.filterable) {
            if(value.filterable.ui == "datetimepicker") {
              value.filterable.cell.template = function (args)
              {
                args.element.kendoDateTimePicker({animation: false});
              }
            }
            else if(value.filterable.ui == "dropdown"){
              value.filterable.cell.template = function (args)
              {
                args.element.kendoDropDownList({
                  dataSource: value.filterable.dataSource,
                  dataTextField: "text",
                  dataValueField: "value",
                  valuePrimitive: true
                });
              }
            }
          }
          columns.key = value;
        });
    };
    $scope.enableCheckbox = function () {
      this.element.find(".k-columns-item :checkbox").prop("disabled", false);
    };
    $scope.checkAllSelected = function () {
      var allChecked = true;
      for (var i = 0; i < $scope.grid.dataSource.data().length; i++) {
        var item = $scope.grid.dataSource.at(i);
        if (!item.checked) {
          allChecked = false;
          break;
        }
      }
      $scope._checkAll = allChecked;
      $('#checkAll').prop("checked", allChecked);
    };

    $scope.SelectAll = function (ev) {
      var items = $scope.grid.dataSource.data();
      $scope._checkAll = ev.target.checked;
      items.forEach(function (item) {
        item.checked = ev.target.checked;
      });
    };
    //objをquerystrに変換
    $scope.getquerystr = function (queryObj) {
      var tail = [];
      angular.forEach(queryObj, function (value, key) {
        if (angular.isArray(value)) {
          if (0 < value.length) {
            angular.forEach(value, function (value2/*, key2*/) {
              if (!angular.isUndefined(value2)) {
                tail.push(key + "=" + encodeURIComponent(value2));
              }
            });
          }
        } else {
          if (!angular.isUndefined(value)) {
            tail.push(key + "=" + encodeURIComponent(value));
          }
        }
      });
      return tail.join("&");
    };
    //$scope.gridの行を取得
    $scope.getGridRowNumber = function () {
      if (angular.isUndefined($scope.grid)) {
        return 0;
      } else {
        return $scope.grid.dataSource.data().length;
      }
    };
    //$scope.gridの全行をチェック
    $scope.checkAllRows = function (flag) {
      var length = $scope.getGridRowNumber();
      for (var i = 0; i < length; i++) {
        var item = $scope.grid.dataSource.at(i);
        item.checked = flag;
      }
    };

    //マイサーチを設定
    $scope.getMySearch = function (index) {
      var sid = $scope._screenId;
      var obj = $cookies.getObject(sid);
      if (angular.isUndefined(obj)) {
        return;
      }
      var conf = obj[index];
      if (angular.isUndefined(conf.filter)) {
        $scope.gridOptions.dataSource.filter([]);
      } else {
        var filterobj = {logic: conf.filter.logic, filters: []};
        angular.forEach(conf.filter.filters, function (filter) {
          if(filter.type == "Date"){
            filter.value = new Date(filter.value);
          }
          filterobj.filters.push({field: filter.field, operator: filter.operator, value: filter.value});
        });
        $scope.gridOptions.dataSource.filter(filterobj);
      }

      $scope.gridOptions.dataSource.sort(conf.sort);
      $scope._mySearchIndex = index;
      $scope._mySearchLabel = $scope.getMySearchLabel(index);
    };

    //$scope.gridのチェック行を取得
    $scope.getCheckedRows = function (idName) {

      var rowGetFlg = angular.isUndefined(idName);
      var checkedRows = [];
      var filters = $scope.grid.dataSource.filter();
      var allData = $scope.grid.dataSource.data();
      var query = new kendo.data.Query(allData);
      var items = query.filter(filters).data;
      var length = items.length;
      for (var i = 0; i < length; i++) {
        var item = items[i];
        if (item.checked) {
          if (rowGetFlg) {
            checkedRows.push(item);
          } else {
            checkedRows.push(item[idName]);
          }
        }
      }
      return checkedRows;
    };
    //マイサーチを保存
    $scope.setMySearch = function (index, label) {
      var sid = $scope._screenId;
      var strCookie = $cookies.get(sid);
      var obj = {};
      if (strCookie) {
        obj = $cookies.getObject(sid);
      }
      obj[index] = {};
      var filterconf = $scope.gridOptions.dataSource.filter();
      var sortconf = $scope.gridOptions.dataSource.sort();

      if (!angular.isUndefined(filterconf)) {
        var filterobj = {logic: filterconf.logic, filters: []};
        angular.forEach(filterconf.filters, function (filter) {
          var type = "";
          var clas = Object.prototype.toString.call(filter.value).slice(8, -1);
          if(clas == "Date"){
            var format = 'YYYY-MM-DD';
            format = format.replace('YYYY', filter.value.getFullYear());
            format = format.replace('MM', ('0' + (filter.value.getMonth() + 1)).slice(-2));
            format = format.replace('DD', ('0' + filter.value.getDate()).slice(-2));
            type = "Date";
            filter.value = format;
          }
          filterobj.filters.push({field: filter.field, operator: filter.operator, value: filter.value, type: type});
        });
        obj[index]["filter"] = filterobj;
      }
      var expire = new Date("2030/01/01 0:00:00");
      var sortobj = [];
      angular.forEach(sortconf, function (sort) {
        sortobj.push({field: sort.field, compare: sort.compare, dir: sort.dir});
      });
      obj[index]["sort"] = sortobj;
      obj[index]["label"] = label;
      $cookies.putObject(String(sid), obj, {
        expires: expire
      });
      $scope["_mySearchLabel" + (parseInt(index) + 1)] = $scope.getMySearchLabel(index);
      $scope.hideModalMySearch();
    };


    //詳細初期化メソッド
    $scope.getDetailInitFunc = function (tabs, params) {
      params = params || {};
      return function (e) {
        var detailRow = e.detailRow;
        detailRow.find(".tabstrip").kendoTabStrip({
          animation: false
        });
        var length = tabs.length;
        for (var i = 0; i < length; i++) {
          var tab = tabs[i];
          (function (tab) {
            if(!$scope._subScreens[tab.tabName])return;
            ScreensService.getConfig($scope._subScreens[tab.tabName])
              .success(function (data) {
                tab.data = data;
                var screenId = data.screenId;
                var gridConf = data.gridConf;
                var datasourceConf = data.datasourceConf;
                var id = e.data[tab.idName];
                var _params = {};
                angular.copy(params, _params);
                if (!angular.isUndefined(tab.params)) {
                  angular.copy(tab.params, _params);
                }
                _params = $scope.getquerystr(_params);
                if (data.edittable) {
                  tab.func(screenId, datasourceConf, id, _params, true)
                    .success(function (data) {
                      angular.extend(e.data, data[0]);
                      $scope._detailedData[detailRow.prevObject[0].rowIndex] = e.data;
                    });
                } else {
                  gridConf.dataSource = tab.func(screenId, datasourceConf, id, _params);
                  var grid = detailRow.find(".screen" + screenId).kendoGrid(gridConf);
                }
              });
          })(tab);
        }
      };
    };
    $scope.resizeVirtualScroll = function () {
      grid = $("#grid").data("kendoGrid");

      //reset the cached rowHeight:
      grid._rowHeight = undefined;
      //refresh the virtualScrollable option
      grid.virtualScrollable.refresh();
    }

    //ロード開始
    $scope._nowloading = false;
    $scope.startLoading = function () {
      document.activeElement.blur();
      $scope._nowloading = true;
      //scrollTo(0, 0);
      var body = angular.element(document.body);
      //body.css("overflow", "hidden");
    };
    $scope.stopLoading = function () {
      $scope._nowloading = false;
      //var body = angular.element(document.body);
      //body.css("overflow", "auto");
    };

    // モーダル位置調整
    $scope.setModalPositionThere = function () {
      $('.ng-modal-dialog').css({
        "left": ($(window).width() - $('.ng-modal-dialog').width()) / 2,
        "top": $(window).scrollTop() + $(window).height() / 2 - 20
      });
    }

    //商品検索モーダルを表示
    $scope.showModalMaterials = function () {
      $scope.setModalPositionThere();
      $scope._modalMaterialsShow = true;
      $scope.modalMaterialsGridShow = false;
    };

    //商品検索モーダルを非表示
    $scope.hideModalMaterials = function () {
      $scope._modalMaterialsShow = false;
    };

    //マイサーチモーダルを非表示
    $scope.hideModalMySearch = function () {
      $scope._modalMySearchShow = false;
    };

    //商品検索モーダルを非表示
    $scope.hideModalExchanges = function () {
      $scope._modalExchangesShow = false;
    };

    //マイサーチモーダルを表示
    $scope.showModalMySearch = function () {
      $document.scrollTop(0, 0);
      $scope._modalMySearchShow = true;
    };

    //商品荷姿検索モーダルを表示
    $scope.showModalMaterialPackages = function () {
      $document.scrollTop(0, 0);
      $scope._modalMaterialPackagesShow = true;
      $scope.modalMaterialPackagesGridShow = false;
    };

    //商品荷姿検索モーダルを非表示
    $scope.hideModalMaterialPackages = function () {
      $scope._modalMaterialPackagesShow = false;
    };

    //ロケーション検索モーダルを表示
    $scope.showModalLocations = function () {
      $scope.setModalPositionThere();
      $scope._modalLocationsShow = true;
      $scope.modalLocationsGridShow = false;
    };

    //ロケーション検索モーダルを非表示
    $scope.hideModalLocations = function () {
      $scope._modalLocationsShow = false;
    };


    //ユーザー、ユニットロール選択モーダルを表示
    $scope.showModalUnitUserPermissions = function () {
      $document.scrollTop(0, 0);
      $scope._modalUnitUserPermissionsShow = true;
    };

    //ユーザー、ユニットロール選択モーダルを非表示
    $scope.hideModalUnitUserPermissions = function () {
      $scope._modalUnitUserPermissionsShow = false;
    };

    //ジョブモーダルを表示
    $scope.showModalJob = function () {
      $document.scrollTop(0, 0);
      $scope._modalJobShow = true;
    };

    //ジョブモーダルを非表示
    $scope.hideModalJob = function () {
      $scope._modalJobShow = false;
    };

    //依頼主モーダルを表示
    $scope.showModalClients = function () {
      $scope.setModalPositionThere();
      var modalScreenId = $scope._screens["依頼主情報選択モーダル"];
      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="radio" name="radiomaterial" ng-click="doSelectClientRadio(dataItem)"></input>'
          });
          gridConf.dataSource = ClientsService.getClients(modalScreenId, datasourceConf);
          $scope.modalClientsGridOptions = gridConf;
        });

      $scope._modalClientsShow = true;
    };
    //依頼主モーダルを非表示
    $scope.hideModalClients = function () {
      $scope._modalClientsShow = false;
    };

    //同梱物モーダルを非表示
    $scope.hideModalNovelties = function () {
      $scope._modalNoveltiesShow = false;
    };

    //届け先モーダルを表示
    $scope.showModalDeliveries = function (client_id) {
      $scope.setModalPositionThere();
      var modalScreenId = $scope._screens["届け先情報選択モーダル"];
      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="radio" name="radiodelivery" ng-click="doSelectDeliveryRadio(dataItem)"></input>'
          });
          gridConf.dataSource = DeliveriesService.getDeliveries(modalScreenId, datasourceConf, {"client_ids[]": client_id});
          $scope.modalDeliveriesGridOptions = gridConf;
        });
      $scope._modalDeliveriesShow = true;
    };
    //届け先モーダルを非表示
    $scope.hideModalDeliveries = function () {
      $scope._modalDeliveriesShow = false;
    };

    //配送情報（路線便）モーダルを表示
    $scope.showModalCarriers = function () {
      $scope.setModalPositionThere();
      var modalScreenId = $scope._screens["配送情報（路線便）選択モーダル"];

      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="radio" name="radioshipments" ng-click="doSelectCarrierRadio(dataItem)"></input>'
          });
          gridConf.dataSource = CarriersService.getAvailableCarriers(modalScreenId, datasourceConf);
          $scope.modalCarriersGridOptions = gridConf;
        });
      $scope._modalCarriersShow = true;
    };

    //配送情報（路線便）モーダルを非表示
    $scope.hideModalCarriers = function () {
      $scope._modalCarriersShow = false;
    };

    //仕入先モーダルを表示
    $scope.showModalSuppliers = function () {
      $scope.setModalPositionThere();
      var modalScreenId = $scope._screens["仕入先選択モーダル"];
      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="radio" name="radiosuppliers" ng-click="doSelectSupplierRadio(dataItem)"></input>'
          });
          gridConf.dataSource = SuppliersService.getSuppliers(modalScreenId, datasourceConf);
          $scope.modalSuppliersGridOptions = gridConf;
          $scope.modalSuppliersGrid.setDataSource(
            SuppliersService.getSuppliers(modalScreenId, datasourceConf)
          );
        });
      $scope._modalSuppliersShow = true;
    };

    //出荷ヘッダーモーダルを非表示
    $scope.hideModalShHeaders = function () {
      $scope._modalShHeadersShow = false;
    };
    //出荷明細モーダルを非表示
    $scope.hideModalShDetails = function () {
      $scope._modalShDetailsShow = false;
    };

    //出荷ヘッダーモーダルを表示
    $scope.showModalShHeaders = function () {
      $document.scrollTop(0, 0);
      $scope._modalShHeadersShow = true;
    };

    //出荷明細モーダルを表示
    $scope.showModalShDetails = function () {
      $document.scrollTop(0, 0);
      var modalScreenId = $scope._screens["返品入荷登録"];
      var params = {
        status: "fixed",
        "sh_header_ids[]": $scope.sh_header.sh_headers_id,
      };
      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="checkbox" ng-model="dataItem.checked" name="checkshdetail" ></input>'
          });
          gridConf.dataSource = ShipmentsService.getShDetails(modalScreenId, datasourceConf, params);
          $scope.modalShDetailsGridOptions = gridConf;
          if (!angular.isUndefined($scope.modalShDetailsGrid)) {
            $scope.modalShDetailsGrid.setDataSource(gridConf.dataSource);
          }
          $scope._modalShDetailsShow = true;
        });
    };

    //仕入先モーダルを非表示
    $scope.hideModalSuppliers = function () {
      $scope._modalSuppliersShow = false;
    };


    //帳票選択モーダルを表示
    $scope.showModalReports = function () {
      $scope.unitReportPattern = null;
      $document.scrollTop(0, 0);

      //出力ボタン有効
      $scope.disablPublishButton = false;
      //表示ボタン非表示
      $scope.exportMode = true;
      $scope.viewMode = false;

      UnitsService.getUnitReports({"unit_screen_id": $scope._screenId})
        .success(function (res) {
          $scope.reports = res;
          if (res.data.length > 0) {
            $scope.unitReportFuncId = res.data[0]['unit_report_func_id'];
            $scope._modalReportsShow = true;
          }
          else {
            ModalFactory.createIModal("E017");
            $scope.unitReportFuncId = 0;
          }
        })
        .error(function (data) {
        });
    };

    //帳票選択モーダルを非表示
    $scope.hideModalReports = function () {
      $scope._modalReportsShow = false;
    };

    //帳票出力 出力完了までポーリングする
    $scope._publishReport = function (primaryKey) {
      if ($scope.unitReportFuncId !== 0 && !$scope.unitReportFuncId) {
        ModalFactory.createIModal("E022");
        return;
      }
      var checkedRowIds = $scope.getCheckedRows(primaryKey);
      if (checkedRowIds.length <= 0) {
        ModalFactory.createIModal("E001");
        return;
      }

      //出力ボタン有効→無効
      $scope.disablePublishButton = true;

      // PDFを出力キューに入れるようサーバにリクエスト
      var prm;
      // 固定パターンの指定がないときは固定パターンidをリクエストに含めない

      if ($scope.unitReportPattern != null &&
        'id' in $scope.unitReportPattern) {
        prm = {
          "ids": checkedRowIds,
          "primary_key": primaryKey,
          "unit_report_func_id": $scope.unitReportFuncId,
          "unit_report_pattern_id": $scope.unitReportPattern.id
        };
      } else {
        prm = {
          "ids": checkedRowIds,
          "primary_key": primaryKey,
          "unit_report_func_id": $scope.unitReportFuncId
        };
      }
      //変数をクリア
      $scope.unitReportPattern = null;

      FileIOService.doPrint(prm)
        .success(function (data) {
          //タスクが終わるまでポーリングして監視
          $scope.asyncPdf(data.data.id);
        })
        .error(function (data) {
          //出力ボタン無効→有効
          $scope.disablePublishButton = false;
          ModalFactory.createIModal("E999");
        });
    };

    //帳票を表示する
    $scope.viewReport = function () {
      //帳票pdfを新規画面に表示
      if ($scope.reportPath) {
        $window.open($scope.reportPath);
      }

      //表示ボタン非表示
      $scope.exportMode = true;
      $scope.viewMode = false;
    };

    //IF選択モーダルを表示
    $scope.showModalImports = function ($apiId) {
      $scope.importMode = true;
      $scope.disableImportButton = false;
      $document.scrollTop(0, 0);
      UnitsService.getUnitInterface({"apiId[]": $apiId})
        .success(function (res) {
          $scope.interfaces = res;
          if (res.data.length > 0) {
            $scope.apiId = res.data[0]['id'];
          }
          else {
            ModalFactory.createIModal("E030");
            $scope.apiId = 0;
            return;
          }
          $scope.disableImportButton = false;
          $scope._modalImportsShow = true;
        })
        .error(function (data) {
          $scope.isDisabled = false;
        });
    };

    //IF選択モーダルを非表示
    $scope.hideModalImports = function () {
      $scope._modalImportsShow = false;
      $scope.isDisabled = false;
    };

    $scope.reload = function () {
      $route.reload();
    };

    //IF出力選択モーダルを表示
    $scope.showModalExports = function ($apiId) {
      //出力ボタン有効
      $scope.disableExportButton = false;
      //出力ボタン表示。表示ボタン非表示
      $scope.exportMode = true;
      $scope.viewMode = false;

      $document.scrollTop(0, 0);
      UnitsService.getUnitInterface({"apiId[]": $apiId})
        .success(function (res) {
          $scope.interfaces = res;
          if (res.data.length > 0) {
            $scope.apiId = res.data[0]['id'];
          }
          else {
            ModalFactory.createIModal("E030");
            $scope.apiId = 0;
            return;
          }
          $scope.disableExportButton = false;
          $scope._modalExportsShow = true;
        })
        .error(function (data) {

        });
    };

    //IF出力選択モーダルを表示
    $scope.showModalShReceiptExports = function ($apiId) {
      //出力ボタン有効
      $scope.disableExportButton = false;
      //出力ボタン表示。表示ボタン非表示
      $scope.exportMode = true;
      $scope.viewMode = false;

      $document.scrollTop(0, 0);
      UnitsService.getUnitShipmentReceipt({"apiId[]": $apiId})
        .success(function (res) {
          $scope.interfaces = res;
          if (res.data.length > 0) {
            $scope.apiId = res.data[0]['id'];
          }
          else {
            ModalFactory.createIModal("E030");
            $scope.apiId = 0;
            return;
          }
          $scope.disableExportButton = false;
          $scope._modalExportsShow = true;
        })
        .error(function (data) {

        });
    };

    //IF出力選択モーダルを非表示
    $scope.hideModalExports = function () {
      $scope._modalExportsShow = false;
    };

    //帳票を表示する
    $scope.viewIfExport = function () {
      //csvを新規画面に表示
      if ($scope.ifExportPath) {
        $window.open($scope.ifExportPath);
      }

      //表示ボタン非表示
      $scope.exportMode = true;
      $scope.viewMode = false;
      $scope._modalExportsShow = false;
    };

    //商品TCフラグ選択モーダルを表示
    $scope.showModalMaterialsIsTc = function () {
      $document.scrollTop(0, 0);
      $scope._modalMaterialsIsTcShow = true;
    };

    //商品TCフラグ選択モーダルを非表示
    $scope.hideModalMaterialsIsTc = function () {
      $scope._modalMaterialsIsTcShow = false;
    };

    //ユニットロールコピーモーダルを表示
    $scope.showModalUnitRolls = function () {
      $document.scrollTop(0, 0);
      $scope._modalUnitRollsShow = true;
    };

    //同梱物モーダルを表示
    $scope.showModalNovelties = function () {
      $document.scrollTop(0, 0);
      $scope._modalNoveltiesSchQty
      var modalScreenId = $scope._screens["同梱物荷姿選択モーダル"];
      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="radio" name="radionovel" ng-click="doSelectNovelRadio(dataItem)"></input>'
          });
          gridConf.dataSource = MaterialsService.getNovelties(modalScreenId, datasourceConf);
          $scope.modalNoveltiesGridOptions = gridConf;
        });
      $scope._modalNoveltiesShow = true;
    };

    //ユニットロールコピーモーダルを非表示
    $scope.hideModalUnitRolls = function () {
      $scope._modalUnitRollsShow = false;
      $scope._nowloading = false;
    };

    //商品選択(モーダル)
    $scope.doSelectMaterial = function () {
      if ($scope.gridConf.dataSource) {
        $scope.gridOptions.dataSource.add(
          $scope.selectedMaterial
        );
      } else {
        $scope.gridConf.dataSource = new kendo.data.DataSource({
          data: [
            $scope.selectedMaterial
          ]
        });
        $scope.gridOptions = $scope.gridConf;
      }
      $scope.hideModalMaterials();
    };
    //ロケーション選択(モーダル)
    $scope.doSelectLocation = function () {
      if ($scope.gridConf.dataSource) {
        $scope.gridOptions.dataSource.add(
          $scope.selectedLocation
        );
      } else {
        $scope.gridConf.dataSource = new kendo.data.DataSource({
          data: [
            $scope.selectedLocation
          ]
        });
        $scope.gridOptions = $scope.gridConf;
      }
      $scope.hideModalLocations();
    };
    //商品荷姿選択(モーダル)
    $scope.doSelectMaterialPackage = function () {
      if ($scope.gridConf.dataSource) {
        $scope.gridOptions.dataSource.add(
          $scope.selectedMaterialPackage
        );
      } else {
        $scope.gridConf.dataSource = new kendo.data.DataSource({
          data: [
            $scope.selectedMaterialPackage
          ]
        });
        $scope.gridOptions = $scope.gridConf;
      }
      $scope.hideModalMaterialPackages();
    };
    //商品radio選択(モーダル)
    $scope.doSelectMaterialRadio = function (dataItem) {
      $scope.selectedMaterial = dataItem;
    };
    //ロケーションradio選択(モーダル)
    $scope.doSelectLocationRadio = function (dataItem) {
      $scope.selectedLocation = dataItem;
    };
    //同梱品radio選択(モーダル)
    $scope.doSelectNovelRadio = function (dataItem) {
      $scope.selectedNovel = dataItem;
    };
    //商品荷姿radio選択(モーダル)
    $scope.doSelectMaterialPackageRadio = function (dataItem) {
      $scope.selectedMaterialPackage = dataItem;
    };
    //依頼主radio選択(モーダル)
    $scope.doSelectClientRadio = function (dataItem) {
      $scope.selectedClient = dataItem;
    };
    //依頼主radio選択(モーダル)
    $scope.doSelectDeliveryRadio = function (dataItem) {
      $scope.selectedDelivery = dataItem;
    };
    //配送情報（路線便）radio選択(モーダル)
    $scope.doSelectCarrierRadio = function (dataItem) {
      $scope.selectedCarrier = dataItem;
    };
    //仕入先radio選択(モーダル)
    $scope.doSelectSupplierRadio = function (dataItem) {
      $scope.selectedSupplier = dataItem;
    };
    //出荷ヘッダーradio選択(モーダル)
    $scope.doSelectShHeaderRadio = function (dataItem) {
      $scope.selectedShHeader = dataItem;
    };
    //商品検索
    $scope.doMaterialsSearch = function () {
      var modalScreenId = $scope._screens["商品選択モーダル"];
      var query = $scope._materialsCodeQuery + " " + $scope._materialsNameQuery;
      if (query === " ") {
        return;
      }
      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="radio" name="radiomaterial" ng-click="doSelectMaterialRadio(dataItem)" />'
          });
          gridConf.dataSource = MaterialsService.doSearch(modalScreenId, datasourceConf, query);
          $scope.modalMaterialsGridOptions = gridConf;
          if (!angular.isUndefined($scope.modalMaterialsGrid)) {
            $scope.modalMaterialsGrid.setDataSource(gridConf.dataSource);
          }
          $scope.selectedMaterial = null;
          $scope.modalMaterialsGridShow = true;
        });
    };
    //ロケ検索
    $scope.doLocationsSearch = function () {
      var modalScreenId = $scope._screens["ロケーション選択モーダル"];
      var query = $scope._locationsCodeQuery;
      if (query === " ") {
        return;
      }
      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="radio" name="radiolocation" ng-click="doSelectLocationRadio(dataItem)" />'
          });
          gridConf.dataSource = LocationsService.doSearch(modalScreenId, datasourceConf, query);
          $scope.modalLocationsGridOptions = gridConf;
          if (!angular.isUndefined($scope.modalLocationsGrid)) {
            $scope.modalLocationsGrid.setDataSource(gridConf.dataSource);
          }
          $scope.selectedLocation = null;
          $scope.modalLocationsGridShow = true;
        });
    };
    //商品荷姿検索
    $scope.doMaterialPackagesSearch = function () {
      var modalScreenId = $scope._screens["商品荷姿選択モーダル（子商品）"];
      var query = $scope._materialsCodeQuery + " " + $scope._materialsNameQuery;
      if (query === " ") {
        return;
      }
      var kind = "pck";
      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="radio" name="radiomaterial" ng-click="doSelectMaterialPackageRadio(dataItem)" />'
          });
          gridConf.dataSource = MaterialsService.doSearchMaterialPackages(modalScreenId, datasourceConf, query, kind);
          $scope.modalMaterialPackagesGridOptions = gridConf;
          if (!angular.isUndefined($scope.modalMaterialPackagesGrid)) {
            $scope.modalMaterialPackagesGrid.setDataSource(gridConf.dataSource);
          }
          $scope.selectedMaterialPackage = null;
          $scope.modalMaterialPackagesGridShow = true;
        });
    };
    //出荷ヘッダー検索
    $scope.doShHeadersSearch = function () {
      var modalScreenId = $scope._screens["出荷ヘッダ選択モーダル"];
      var params = {
        status: "fixed",
        target: $scope._shHeadersQueryType,
        value: $scope._shHeadersQuery
      };
      if ($scope._shHeadersQuery === "") {
        return;
      }
      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="radio" name="radioshheader" ng-click="doSelectShHeaderRadio(dataItem)"></input>'
          });
          gridConf.dataSource = ShipmentsService.doSearchShHeaders(modalScreenId, datasourceConf, params);
          $scope.modalShHeadersGridOptions = gridConf;
          if (!angular.isUndefined($scope.modalShHeadersGrid)) {
            $scope.modalShHeadersGrid.setDataSource(gridConf.dataSource);
          }
        });
    };

    //ロケ選択モーダルを表示
    $scope.showModalMaterialPackageLocations = function (isFace, index) {
      $scope.setModalPositionThere();
      var modalScreenId = $scope._screens["商品荷姿ロケ選択モーダル"];
      $scope.isFace = isFace;
      $scope.materialPackageLocationIndex = index;

      ScreensService.getConfig(modalScreenId)
        .success(function (data) {
          var gridConf = data.gridConf;
          var datasourceConf = data.datasourceConf;
          //radioを追加
          gridConf.columns.unshift({
            field: "check_row",
            filterable: false,
            sortable: false,
            title: " ",
            width: 30,
            template: '<input type="radio" name="radioMaterialPackageLocations" ng-click="doSelectMaterialPackageLocationRadio(dataItem)"/>'
          });
          gridConf.dataSource = LocationsService.getLocations(modalScreenId, datasourceConf);
          $scope.modalMaterialPackageLocationsGridOptions = gridConf;
        });
      $scope._modalMaterialPackageLocationsShow = true;
    };

    //ロケ選択モーダルを非表示
    $scope.hideModalMaterialPackageLocations = function () {
      $scope._modalMaterialPackageLocationsShow = false;
    };

    //ロケradio選択(モーダル)
    $scope.doSelectMaterialPackageLocationRadio = function (dataItem) {
      $scope.selectedMaterialPackageLocation = dataItem;
    };

    //予定日一括変更モーダルを表示
    $scope.showModalMultiUpdate = function () {
      $scope.multi_update_modal_field = "sch_shp_dt";
      $scope._modalMultiUpdateShow = true;
    };
    //予定日一括変更モーダルを非表示
    $scope.hideModalMultiUpdate = function () {
      $scope._modalMultiUpdateShow = false;
    };

    //出荷個口変更モーダルを表示
    $scope.showModalShPieceUpdate = function () {
      $scope._modalShPieceUpdateShow = true;
    };
    //出荷個口変更モーダルを非表示
    $scope.hideModalShPieceUpdate = function () {
      $scope._modalShPieceUpdateShow = false;
    };

    //帳票設定編集モーダル
    $scope.hideModalUnitReportFuncs = function () {
      $scope._modalUnitReportFuncsShow = false;
    };

    $scope.showModalChangeShPrcStatus = function () {
      $scope._modalChangeShPrcStatusShow = true;
    };

    $scope.hideModalChangeShPrcStatus = function () {
      $scope._modalChangeShPrcStatusShow = false;
    };

    //大森廻漕店 出荷情報更新モーダルを表示
    $scope.showModalCustomMultiUpdate = function () {
      $scope.pay_mtd_code_id = "133";
      $scope._modalCustomMultiUpdateShow = true;
    };
    //大森廻漕店 出荷情報更新モーダルを非表示
    $scope.hideModalCustomMultiUpdate = function () {
      $scope._modalCustomMultiUpdateShow = false;
    };

    //帳票選択画面
    $scope._doPrint = function (primaryKey) {
      var checkedRowIds = $scope.getCheckedRows(primaryKey);
      if (checkedRowIds.length <= 0) {
        ModalFactory.createIModal("E001");
        return;
      }
      $scope.disablePublishButton = false;
      $scope.showModalReports();
    };

    $scope.onSelectImports = function (file) {
      $scope.file = file;
    };
    //IF取込
    $scope.doImport = function () {
      $scope.importMode = true;
      $scope.disableImportButton = false;

      if ($scope.apiId !== 0 && !$scope.apiId) {
        $window.confirm("インターフェースタイプが選択されていません。");
        return;
      }
      if (!$scope.file) {
        $window.confirm("ファイルが選択されていません。");
        return;
      }

      $scope.disableImportButton = true;

      $scope.upload = Upload.upload({
        url: '/api/v2/import/publish',
        method: 'POST',
        fields: {"api_id": $scope.apiId},
        file: $scope.file
      }).progress(function (evt) {
      }).success(function (data, status, headers, config) {
        if (data.data.error) {
          $scope.importMode = false;
          $scope.disableImportButton = false;
          $scope.hasErrorLog = false;
          $scope.resultTitle = "エラーが発生しました。";
          $scope.resultMessage = "詳細\r\n\r\n" + data.data.result;
        } else {
          //ボーリングで取込状態を確認
          $scope.asyncIfImport(data.data.result.id);
        }
      }).error(function (data, status, headers, config) {
        $scope.hideModalImports();
        $window.confirm("サーバーと通信できませんでした。");
        $scope.disableImportButton = false;
      });
    };

    //IF出力
    $scope._doExport = function (primaryKey) {
      var checkedRowIds = $scope.getCheckedRows(primaryKey);
      if ($scope.apiId !== 0 && !$scope.apiId) {
        $window.confirm("インターフェースタイプが選択されていません。");
        return;
      }

      $scope.disableExportButton = true;

      FileIOService.doExport({"api_id": $scope.apiId, "ids[]": checkedRowIds, "unit_pattern": $scope.ifUnitPattern})
        .success(function (data) {
          if (data.data.error) {
            $window.confirm("エラーが発生しました。\r\n\r\n詳細:\r\n\r\n" + data.data.result);
            $scope.disableExportButton = false;
          } else {
            //ボーリングで出力状態を確認
            $scope.asyncIfExport(data.data.result.id);
          }
        })
        .error(function (data) {
          $scope.hideModalImports();
          $window.confirm("サーバーと通信できませんでした。");
          $scope.disableExportButton = false;
        });
    };

    $scope.onSelectExchanges = function (file) {
      $scope.exchangeFile = file;
    };
    //IF取込
    $scope._doExchange = function (apiId) {
      if (!$scope.exchangeFile) {
        $window.confirm("ファイルが選択されていません。");
        return;
      }
      $scope.disableExchangeButton = true;

      $scope.upload = Upload.upload({
        url: '/api/v2/exchange',
        method: 'POST',
        file: $scope.exchangeFile,
        fields: {"api_id": apiId}
      }).success(function (data, status, headers, config) {
        $scope.exchangeMode = false;
        $scope.viewMode = true;
        $scope.disableExchangeButton = false;
        $scope.resultTitle = "取込が完了しました";
        $scope.resultMessage =
          "IF取込み履歴より結果を確認してください。";
      }).error(function (data, status, headers, config) {
        $scope.hideModalExchanges();
        $window.confirm("サーバーと通信できませんでした。");
        $scope.disableImportButton = false;
      });
    };

    //CSVボタンを押したら確認ダイアログを出す
    $scope.getCsv = function () {
      UnitScreensService.getUnitScreensCanCsv({"unit_screen_id": $scope._screenId})
        .success(function (res) {
          if (res[0].can_csv == true) {
            var checkedRowIds = $scope.getChecked();
            if (checkedRowIds.length <= 0) {
              ModalFactory.createIModal("E001");
            } else {
              ModalFactory.createWModal('Q015').then(function () {
                $scope.startLoading();
                $scope._getCsv();
                $scope.stopLoading();
              });
            }
          } else {
            ModalFactory.createIModal("E017");
            $scope.unitReportFuncId = 0;
          }
        })
        .error(function (data) {
          ModalFactory.createEModalByServerData(data);
        });
    };

    //CSV出力(チェックしたものだけ)
    $scope._getCsv = function () {
      var grid = $("#grid").data("kendoGrid");
      var csv = '';
      var fileName = 'download.csv';

      //表の取得
      var filters = $scope.grid.dataSource.filter();
      var allData = $scope.grid.dataSource.data();
      var query = new kendo.data.Query(allData);
      var data = query.filter(filters).data;


      //ヘッダ追加
      for (var i = 0; i < grid.columns.length; i++) {
        // 0番目には値が入っていないのでスキップ
        if (i === 0) {
          continue;
        }
        var field = grid.columns[i].field;
        var title = grid.columns[i].title || field;

        if (!field) {
          continue;
        }

        //文字で囲う
        title = title.replace(/"/g, '""');
        //htmlタグの除去
        title = title.replace(/(<([^>]+)>)/ig, "");

        csv += '"' + title + '"';

        //区切り文字追加
        if (i < grid.columns.length - 2) {
          csv += ',';
        }
      }
      csv += '\r\n';

      // データ行
      var rowCnt = 0;
      for (var row in data) {
        var item = data[rowCnt];
        rowCnt++;
        if (!item || !item.checked) {
          continue;
        }
        for (var i = 0; i < grid.columns.length; i++) {
          // 0番目には値が入っていないのでスキップ
          if (i === 0) {
            continue;
          }
          var fieldName = grid.columns[i].field;
          var template = grid.columns[i].template;
          var exportFormat = grid.columns[i].exportFormat;

          if (!fieldName) {
            continue;
          }
          var value = '';
          //プロパティありの場合
          if (fieldName.indexOf('.') >= 0) {
            var properties = fieldName.split('.');
            value = data[row] || '';
            for (var j = 0; j < properties.length; j++) {
              var prop = properties[j];
              value = value[prop] || '';
            }
          } else {
            value = data[row][fieldName] == null ? '' : data[row][fieldName];
          }
          if (value && template && exportFormat !== false) {
            value = jQuery.isFunction(template) ? template(data[row]) : kendo.template(template)(data[row]);
          }
          else if (value.getDate) {
            value = kendo.toString(new Date(value), exportFormat);
          }

          value = value.toString().replace(/"/g, '""');
          //htmlタグの除去
          value = value.replace(/(<([^>]+)>)/ig, "");

          csv += '"' + value + '"';

          //区切り文字追加
          if (i < grid.columns.length - 2) {
            csv += ',';
          }
        }
        csv += '\r\n';
      }

      //文字コードを変換
      var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

      //スクリーン名を取得
      var screenName = "名称未設定";
      Object.keys($scope._screens).forEach(function (key) {
        if ($scope._screens[key] === $scope._screenId) {
          screenName = key;
        }
      });

      //ファイル名の設定
      var now = new Date();
      fileName = screenName
      + "_" + now.getFullYear()
      + "_" + (now.getMonth() + 1)
      + "_" + now.getDate()
      + "_" + now.getHours()
      + "_" + now.getMinutes()
      + "_" + now.getSeconds() + ".csv";

      var blob = new Blob([bom, csv], {
        type: "text/csv;"
      });
      //ファイルの保存(IE, Safari非対応)
      var downloadLink = $('<a></a>');
      downloadLink.attr('href', window.URL.createObjectURL(blob));
      downloadLink.attr('download', fileName);
      downloadLink.attr('target', '_blank');

      $('body').append(downloadLink);
      downloadLink[0].click();
      downloadLink.remove();
    };

    $(window).resize(function () {
      $scope.resizeGrid();
    });

    $scope.resizeGrid = function () {
      var gridElement = $("#grid");
      var newGridHeight;
      if ($scope.showHeader) {
        newGridHeight = $(document).height() - 320;
      } else {
        newGridHeight = $(document).height() - 169;
      }
      gridElement.height(newGridHeight);
      if (gridElement.data("kendoGrid")) {
        gridElement.data("kendoGrid").refresh();
      }
    };
    // PDFの出力状況を定期で確認する。
    $scope.asyncPdf = function (param) {

      var getPromise = function (param) {
        var deferred = $q.defer();

        var promise = $interval(function () {
            deferred.notify();
            FileIOService.getPrintStatus(param)
              .success(function (data) {
                //report_headersにデータがない
                if (data === null) {
                  ModalFactory.createIModalByStr("帳票出力要求が失敗しました。再度帳票の作成を実行して下さい。")
                  .then(function(){
                    $interval.cancel(promise);
                    deferred.reject('帳票出力要求が失敗しました。再度帳票の作成を実行して下さい。');
                  });
                } else if (data.is_finished) {
                  if (data.success) {
                    //PDFファイル出力成功
                    deferred.resolve(data.file_nm);
                    $interval.cancel(promise);

                    var printName = data.file_nm.substring(data.file_nm.lastIndexOf('/'));

                    //出力ボタン　無効→有効
                    $scope.disablePublishButton = false;
                    //表示ボタン表示
                    $scope.exportMode = false;
                    $scope.viewMode = true;

                    $scope.reportPath = FileIOService.getDownloadLinkReport(param) + printName;
                  } else {
                    //PDFファイル出力失敗
                    $interval.cancel(promise);
                    ModalFactory.createIModalByStr("帳票の出力に失敗しました。\r\n理由：\r\n" + data.msg)
                    .then(function(){
                      //出力ボタン　無効→有効
                      $scope.disablePublishButton = false;
                      deferred.reject("帳票の出力に失敗しました。\r\n理由：\r\n" + data.msg);
                    });
                  }
                }
              })
              .error(function () {
                //出力ボタン　無効→有効
                $scope.disablePublishButton = false;
                deferred.reject();
                ModalFactory.createIModalByStr('サーバーと通信できませんでした。')
                .then(function(){
                  $interval.cancel(promise);
                });

              });
          }
          , 1000);

        return deferred.promise;
      };

      var promise = getPromise(param);

      promise.then(
        function (path) {

        },
        function (msg) {

        },
        function () {
        }
      )
    };

    // IFの入力状況を定期で確認する。
    $scope.asyncIfImport = function (param) {

      var getPromise = function (param) {
        var deferred = $q.defer();

        var promise = $interval(function () {
            deferred.notify();
            FileIOService.getIfStatus(param)
              .success(function (data) {

                //if_Resultsにデータがない
                if (!data) {
                  $scope.importMode = false;
                  $scope.disableImportButton = false;
                  $scope.hasErrorLog = false;

                  $scope.resultTitle = "csv取込に失敗しました。";
                  $scope.resultMessage = "再度取込処理を実行して下さい。";
                  $interval.cancel(promise);
                  deferred.reject('csv取込に失敗しました。再度取込処理を実行して下さい。');
                  return;
                }

                $scope.ifResultId = data.id;

                if (data.is_finished) {
                  $scope.importMode = false;
                  $scope.disableImportButton = false;

                  if (data.msg) {
                    $scope.hasErrorLog = false;
                    $scope.resultTitle = "取込に失敗しました";
                    $scope.resultMessage = "詳細:\r\n\r\n" + data.msg;

                  } else if (data.err_cnt) {
                    $scope.hasErrorLog = true;
                    // スラッシュが入っているとパスがおかしくなるのでエスケープ
                    $scope.errPth = data.err_pth.replace(/\//g, "_");
                    $scope.resultTitle = "取込が完了しました";
                    $scope.resultMessage =
                      "成功 : " + data.cnt + " 件\r\n"
                      + "失敗 : " + data.err_cnt + " 件\r\n";
                  } else {
                    $scope.hasErrorLog = false;
                    $scope.resultTitle = "取込が完了しました";
                    $scope.resultMessage =
                      "成功 : " + data.cnt + " 件\r\n"
                      + "失敗 : " + data.err_cnt + " 件\r\n";
                  }
                  //IFファイル取込成功
                  deferred.resolve(data.pth);
                  $interval.cancel(promise);
                }
              })
              .error(function () {
                deferred.reject();
                $interval.cancel(promise);
                ModalFactory.createIModalByStr('サーバーと通信できませんでした。')
                .then(function(){
                  $scope.disableImportButton = false;
                });
              });
          }
          , 1000);

        return deferred.promise;
      };

      var promise = getPromise(param);

      promise.then(
        function (path) {

        },
        function (msg) {

        },
        function () {
        }
      )

    };

    $scope.downloadErrLog = function () {
      if ($scope.ifResultId) {
        $window.open(FileIOService.getDownloadLinkIfLogFile($scope.ifResultId, $scope.errPth));
      }
      $scope.importMode = true;
    };

    // IFの出力状況を定期で確認する。
    $scope.asyncIfExport = function (param) {

      var getPromise = function (param) {
        var deferred = $q.defer();

        var promise = $interval(function () {
            deferred.notify();
            FileIOService.getIfStatus(param)
              .success(function (data) {
                //if_Resultsにデータがない
                if (data === null) {
                  ModalFactory.createIModalByStr("csv出力に失敗しました。再度出力処理を実行して下さい。")
                  .then(function(){
                    $interval.cancel(promise);
                    deferred.reject('csv出力に失敗しました。再度出力処理を実行して下さい。');
                    $scope.disableExportButton = false;
                  });
                  return;
                }

                if (data.is_finished) {
                  $scope.disableExportButton = false;
                  if (data.msg) {
                    //IFファイル出力失敗
                    $interval.cancel(promise);
                    ModalFactory.createIModalByStr("ダウンロードに失敗しました。\r\n\r\n"
                    + "理由 : \r\n"
                    + data.msg)
                    .then(function(){
                    });
                  }
                  else if (data.pth) {
                    //IFファイル出力成功
                    deferred.resolve(data.pth);
                    $interval.cancel(promise);

                    var printName = data.file_nm.substring(data.file_nm.lastIndexOf('/') + 1);
                    $scope.ifExportPath = FileIOService.getDownloadLinkIfFile(param, printName);
                    //出力ボタン 非表示。表示ボタン表示
                    $scope.exportMode = false;
                    $scope.viewMode = true;
                  } else {
                    //IFファイル出力失敗
                    $interval.cancel(promise);
                    ModalFactory.createIModalByStr("csvの出力に失敗しました。\r\n理由：\r\n" + data.msg)
                    .then(function(){
                      deferred.reject("csvの出力に失敗しました。\r\n理由：\r\n" + data.msg);
                    });
                  }
                }
              })
              .error(function () {
                deferred.reject();
                $interval.cancel(promise);
                ModalFactory.createIModalByStr('サーバーとの通信に失敗しました')
                .then(function(){
                  $scope.disableExportButton = false;
                });
              });
          }
          , 1000);

        return deferred.promise;
      };

      var promise = getPromise(param);

      promise.then(
        function (path) {

        },
        function (msg) {

        },
        function () {
        }
      )
    };

    $scope.initDate = function () {
      var today = new Date();
      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      $scope.from_date = today;
      $scope.to_date = tomorrow;
    };


    $scope.stateFilter = function (element) {
      element.kendoDropDownList({
          optionLabel: "-Select-"
        }
      )
    };

    $scope.dofocus = function(target) {
        var element = document.getElementById(target);
        element.focus(); // カーソルを合わせる
      };
  };

  //add dependency
  BaseController.$inject = [
    '$scope', '$rootScope', '$location', '$window', '$routeParams',
    '$http', '$route', '$q', '$interval', '$filter', '$document', '$cookies', 'Upload', 'UnitsService',
    'AuthService', 'ScreensService', 'MaterialsService',
    'ClientsService', 'DeliveriesService', 'ShipmentsService',
    'CarriersService', 'SuppliersService', 'LocationsService', 'FileIOService', 'UnitScreensService', 'ModalFactory',
    'MAX_SELECTION'
  ];
  ns.App.controller('BaseController', BaseController);
}());
