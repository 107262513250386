var ns;
// アプリケーションのバージョン
ns.App.constant('VERSION', '1.0.0');
// エンドポイントのバージョン
ns.App.constant('API_ENDPOINT', '/api/v2');
// システム名
ns.App.constant('SYSTEM_NAME', 'W3 SIRIUS');
// APIリクエスト時に簡易レスポンスを取得するためのパラメータ
ns.App.constant('BRIEF_PARAM', 'r_format=brief');
// 最大選択件数
ns.App.constant('MAX_SELECTION', 200);
// 最大表示件数
var max_value = 30000;
ns.App.constant('MAX_DISPLAY', max_value);
// 一覧、明細遷移時の最大選択件数
ns.App.constant('MAX_TRANSIT_SELECTION', 500);
// 履歴表示期間
ns.App.constant('HISTORY_SEARCH_MAX_RANGE', 90);
ns.App.constant('HISTORY_SEARCH_RANGE', 0);
//コード一覧
ns.App.constant('MES_MST', {
  I001: {title: '処理完了', message: '正常に完了しました。'},
  I002: {title: '仮引当結果', message: '選択されたすべての商品が引当可能です。'},
  I003: {title: '引当結果（正常）', message: '処理が正常に完了しました。引当結果確認画面をご確認ください。'},
  I004: {title: 'TC品仕分け選択エラー', message: 'TC品仕分けが必要なデータが含まれておりませんでした。'},
  I005: {title: '個口数分割確認', message: '個口数を出荷予定数量分作成します。'},
  E001: {title: '行未選択エラー', message: '対象とする行を選択して、当ボタンを再度クリックしてください。'},
  E002: {title: '編集不可エラー', message: '確定された行が含まれるため、実施できません。'},
  E003: {title: '編集エラー', message: '実績が既に登録されている行を選択しているため、編集できません.実績登録済のデータは実績画面から編集してください。'},
  E004: {title: 'ネットワークエラー', message: '実績が既に登録されている行を選択しているため、編集できません.実績登録済のデータは実績画面から編集してください。'},
  E005: {title: '引当結果（異常）', message: '一部不足引当が発生しております。不足引当に対するその他ロットからの補填は、出荷指示明細画面の「マニュアル引当」をご利用ください。そのまま強制出荷するもしくは、引当キャンセルする場合は、引当結果確認画面のにて実施してください。'},
  E006: {title: '出荷確定データ選択エラー', message: '出荷確定しているデータが存在するため実施できません。'},
  E007: {title: '行複数選択エラー', message: '当機能は、複数行選択できません。1行のみ選択し再度クリックしてください。'},
  E009: {title: '取込エラー', message: '取込時にエラーが発生しました。詳細をご確認ください。'},
  E010: {title: '未入力エラー', message: '{x}が入力されていない行があります。再度ご入力の上、実施してください。'},
  E011: {title: '不在エラー', message: '引当可能な出荷指示がありません。'},
  E012: {title: '削除エラー', message: '引当が既に実施されているため削除できません。引当結果管理画面にて引当キャンセルを実施した上で、削除してください。'},
  E013: {title: '期間エラー', message: '期間設定範囲を3か月以内に設定してください。'},
  E014: {title: '引当エラー', message: '引当実行が行われている明細です。修正するためには引当キャンセルを行って下さい。'},
  E015: {title: 'マスタ未選択エラー', message: '{x}が選択されておりません。'},
  E016: {title: 'ユニット複数選択エラー', message: 'ユニットが複数選択されているため当機能は利用できません。トップ画面にてユニットを1つ選んで実行してください。'},
  E999: {title: 'システムエラー', message: '予期せぬエラーが発生しました。'},
  E017: {title: '権限エラー', message: '申し訳ございませんが、現在利用権限がございません。ご利用される場合は、責任者にお問合せください。'},
  E018: {title: '作業中エラー', message: '選択された在庫は、他の在庫変更機能で変更中です。変更作業が完了してから実施してください。'},
  E019: {title: '不足エラー', message: '{x}が不足しています。再度ご確認ください。'},
  E020: {title: '超過エラー', message: '{x}が超過しています。再度ご確認ください。'},
  E021: {title: '余剰エラー', message: '変更先荷姿に変更する際にあまりが出てしまいます。あまりが出ないように入力してください。'},
  E022: {title: '帳票タイプ未選択エラー', message: '帳票タイプが選択されていません。選択後に再度クリックしてください。'},
  E023: {title: '複数行選択エラー', message: '１行のみ選択して、当ボタンを再度クリックしてください。'},
  E024: {title: '選択数超過', message: '選択明細件数が200件を超えております。全選択した場合初めの200件を取得し表示します。'},
  E025: {title: '編集エラー', message: '引当が既に実施されているため修正できません。引当結果管理画面にて引当キャンセルを実施した上で、修正してください。'},
  E026: {title: '編集エラー', message: '引当済の出荷指示が含まれています。一括配送業者設定を行いたい出荷指示の引当をキャンセルしてください。'},
  E027: {title: '表示期間エラー', message: '表示可能な期間は最大90日間です。'},
  E028: {title: '選択数超過', message: '選択明細件数が500件を超えております。'},
  E029: {title: '表示件数超過', message: max_value + '件を超えております。\n' + '検索エリアから検索してください。'},
  E030: {title: '利用可能CSV無し', message: '現在利用可能なテンプレートがありません。CSV設定をご確認ください'},
  E031: {title: '編集エラー', message: '実績入力未完了の商品があるため、実施できません.'},
  Q001: {title: '実行確認', message: '実行します。よろしいですか？'},
  Q002: {title: '確認', message: '入力された内容で更新しますよろしいですか？'},
  Q003: {title: '警告', message: '入力された内容が登録されませんがよろしいですか？'},
  Q004: {title: '警告', message: 'この処理は元に戻せません。よろしいですか？'},
  Q005: {title: '警告', message: '実績数と予定数が異なるデータが存在します。実績確定した場合、強制確定となり編集できなくなります。この処理は元に戻せません。よろしいですか？'},
  Q006: {title: '警告', message: '選択レコード数によっては処理時間が長い場合があります。実施してもよろしいですか？'},
  Q007: {title: '警告', message: '全未引当データ対象のため、処理時間が長い場合があります。実施してもよろしいですか？'},
  Q008: {title: '確認', message: '未引当データが存在します。未引当データの場合、引当数が0で強制出荷となります。よろしいですか？'},
  Q009: {title: '警告', message: '実績数　>　予定数のデータが存在します。実績確定した場合、強制入荷確定となり編集できなくなります。この処理は元に戻せません。よろしいですか？'},
  Q010: {title: '警告', message: '選択された行の棚卸をキャンセルします。キャンセルすると在庫は棚卸前に戻されます。調査結果は全て削除されます。よろしいでしょうか？'},
  Q011: {title: '警告', message: '実績確定後は、データを元に戻せませんが、実行しますか？'},
  Q012: {title: '警告', message: '選択された行の調査結果を削除されます。よろしいでしょうか？'},
  Q013: {title: '警告', message: '削除しますがよろしいですか？'},
  Q014: {title: '警告', message: '予定データどおりの入荷数で実績確定されます。よろしいですか？'},
  Q015: {title: '確認', message: 'チェックしたレコードをファイルに出力します。よろしいですか？'},
  Q016: {title: '警告', message: '消費期限が入荷在庫日より過去日が入力されていますがよろしいですか？'},
  Q017: {title: '警告', message: '選択されたレコードを検品キャンセルします。よろしいですか？'},
  Q018: {title: '確認', message: '問番情報を送信します。よろしいですか？'},
  Q019: {title: '確認', message: '入庫在庫情報を送信します。よろしいですか？'},
  Q020: {title: '警告', message: '対象行の明細行も削除されます。よろしいですか？'},
  Q021: {title: '確認', message: '上位システムに棚卸結果を報告します。すべての棚卸が完了していることを確認してください。実行しますか？'},
  Q022: {title: '警告', message: 'グループ化後は、データを元に戻せませんが、実行しますか？'},
});

// option値(以下はサンプル)
ns.App.constant('options', (function(){
  return {
    request_sort_options : [
      {value: "desc", label: '新しい順'},
      {value: "asc", label: '古い順'},
    ]
  };
})());
