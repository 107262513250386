var ns;
ns.App.factory('ShipmentsService', function(
  HttpFactory, API_ENDPOINT, DsFactory, Utils
  ){
  return {
    getShHeaders: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getShFeatures: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_features?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getShFeaturesData: function (sid, params)
    {
      var endpoint = API_ENDPOINT + '/sh_features/edits?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    deleteShFeatures: function(params){
      //var endpoint = API_ENDPOINT + '/ar_headers/batch_delete';
      var endpoint = API_ENDPOINT + '/sh_features/batch_delete';
      if (!angular.isUndefined(params)) {
        endpoint += "?" + Utils.getQueryStr(params);
      }
      return HttpFactory.createDelete(endpoint);
    },
    getShHeadersSchShpDt: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers_sch_shp_dt?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getShHeadersData: function (sid, params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getShHeaderCountsData: function (status)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/counts?status=' + status;
      return HttpFactory.createGet(endpoint);
    },
    deleteShHeaders: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_delete';
      if (!angular.isUndefined(params)) {
        endpoint += "?" + Utils.getQueryStr(params);
      }
      return HttpFactory.createDelete(endpoint);
    },
    getShHeader: function (sid, dsConf, id)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getShHeaderData: function (sid, id, params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/' + id + "?screen_id=" + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    doSearchShHeaders: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/search?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getShHeadersFilteredTakeOut: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/takeout?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getShDetails: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_details';
      var prefix = "?";
      if (!angular.isUndefined(sid)) {
        endpoint += '?screen_id=' + sid;
        prefix = "&";
      }
      if (!angular.isUndefined(params)) {
        endpoint += prefix + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getShDetailsData: function (sid, params)
    {
      var endpoint = API_ENDPOINT + '/sh_details?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint, params);
    },
    deleteShDetails: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_details/batch_delete';
      if (!angular.isUndefined(params)) {
        endpoint += "?" + Utils.getQueryStr(params);
      }
      return HttpFactory.createDelete(endpoint);
    },
    getShDetail: function (sid, dsConf, id)
    {
      var endpoint = API_ENDPOINT + '/sh_details/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getShDetailData: function (sid, id)
    {
      var endpoint = API_ENDPOINT + '/sh_details/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getShResults: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_results?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getShResultsData: function (sid, params)
    {
      var endpoint = API_ENDPOINT + '/sh_results?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint, params);
    },
    getShResult: function (sid, dsConf, id)
    {
      var endpoint = API_ENDPOINT + '/sh_results/' + id + "?screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getShResultForAllocationDetails: function (sid, dsConf, sh_detail_id)
    {
      var endpoint = API_ENDPOINT + '/sh_results?screen_id=' + sid + "&status=allocatable&sh_detail_ids[]=" + sh_detail_id;
      return DsFactory.create(dsConf, endpoint);
    },
    getShResultData: function (sid, id)
    {
      var endpoint = API_ENDPOINT + '/sh_results/' + id + "?screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getShWorkResults: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_work_histories?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getShWorkResult: function (sid, dsConf, id)
    {
      var endpoint = API_ENDPOINT + '/sh_work_histories?sh_result_ids[]=' + id + "&screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getShSmallLotHeaders: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_headers?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getShSmallLotHeadersData: function (sid, params)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_headers?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getShSmallLotHeader: function (sid, dsConf, id)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_headers?sh_header_ids[]=' + id + "&screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getShSmallLotHeaderData: function (sid, id)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_headers?sh_header_ids[]=' + id + "&screen_id=" + sid;
      return HttpFactory.createGet(endpoint);
    },
    getShSmallLotHeaderGroupByShHeaderData: function (sid, id)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_headers/index_grouped_in_sh_header?screen_id=' + sid + "&sh_headers_id=" + id;
      return HttpFactory.createGet(endpoint);
    },
    // 出荷個口情報を更新する（問番、便種）
    updateShSmallLotHeader: function (shHeadersId, shSmallLotHeader)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_headers/update_sh_piece';
      return HttpFactory.createPost(endpoint, {"sh_headers_id": shHeadersId, "sh_small_lot_header": shSmallLotHeader});
    },
    // 出荷個口情報を削除する
    deleteShSmallLotHeader: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_headers/delete_sh_piece';
      return HttpFactory.createPost(endpoint, params);
    },
    // trigger
    triggerTransport: function (sid, shHeaderId)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_header/trigger_transport';
      return HttpFactory.createPost(endpoint, {'screen_id': sid, 'sh_headers_ids': shHeaderId});
    },

    getShSmallLotDetails: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_details?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    getShSmallLotDetailsData: function (sid, params)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_details?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return HttpFactory.createGet(endpoint);
    },
    getShSmallLotDetail: function (sid, dsConf, id)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_details?sh_result_ids[]=' + id + "&screen_id=" + sid;
      return DsFactory.create(dsConf, endpoint);
    },
    getUnitSettingsForJobbingData: function ()
    {
      var endpoint = API_ENDPOINT + '/sh_headers/get_unit_settings_for_jobbing';
      return HttpFactory.createGet(endpoint);
    },
    getProgressOverviewData: function ()
    {
      var endpoint = API_ENDPOINT + '/sh_progress/overview';
      return HttpFactory.createGet(endpoint);
    },
    getProgressBatchesData: function ()
    {
      var endpoint = API_ENDPOINT + '/sh_progress/batches';
      return HttpFactory.createGet(endpoint);
    },
    getProgressDetailData: function ()
    {
      var endpoint = API_ENDPOINT + '/sh_progress/detail';
      return HttpFactory.createGet(endpoint);
    },
    // 在庫出荷指示登録・修正
    storeFromStocks: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_store_from_stocks';
      return HttpFactory.createPost(endpoint, params);
    },
    // FN_30020 届け先重複確認
    indexTheSameDeliveries: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/index_the_same_deliveries?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    // 問番未発行確認
    indexNotAssignedTrackingNumber: function (sid, dsConf, params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/index_not_assigned_tracking_number?screen_id=' + sid;
      if (!angular.isUndefined(params)) {
        endpoint += "&" + Utils.getQueryStr(params);
      }
      return DsFactory.create(dsConf, endpoint);
    },
    // FN_30025 TC仕分番号採番
    assignTcSortNo: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_assign_tc_sort_no';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30030 バッチグルーピング
    groupByBatch: function (ids, batchname)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_group_by_batch';
      return HttpFactory.createPost(endpoint, {"ids": ids, "batch_name": batchname});
    },
    // FN_30050 自動引当(for header)
    allocateAutomaticallyForHeader: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_allocate_automatically';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30050 自動引当(for detail)
    allocateAutomaticallyForDetail: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_details/batch_allocate_automatically';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30060 強制引当(for header)
    allocateForciblyForHeader: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_allocate_forcibly';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30060 強制引当(for detail)
    allocateForciblyForDetail: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_details/batch_allocate_forcibly';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30070 引当キャンセル
    cancelAllocationForHeader: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_cancel_allocation';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30070 引当キャンセル
    cancelAllocationForDetail: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_details/batch_cancel_allocation';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30080 送り状発行ソフトCSV出力
    exportShipmentReceiptSchedule: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_export_shipment_receipt_schedule';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30085 送り状発行ソフトCSV取込
    importShipmentReceiptResult: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_export_shipment_receipt_result';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },

    // FN_30100 手動引当登録
    allocateManually: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_details/batch_allocate_manually';
      return HttpFactory.createPost(endpoint, {"data": params});
    },
    // FN_30130 GAS指示CSV出力
    // FN_30140 GAS結果CSV取込
    // FN_30160 検品登録・修正
    upsertInspectionForHeader: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_store_or_update_inspection';
      return HttpFactory.createPost(endpoint, params);
    },
    upsertInspectionForResult: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_results/batch_store_or_update_inspection';
      return HttpFactory.createPost(endpoint, params);
    },
    // FN_30180 加工登録・修正
    upsertProcessForHeader: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_store_or_update_process';
      return HttpFactory.createPost(endpoint, params);
    },
    upsertProcessForHeaderResult: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_results/batch_store_or_update_process';
      return HttpFactory.createPost(endpoint, params);
    },

    // FN_30190 検品キャンセル
    cancelInspectionForHeader: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_cancel_inspection';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    cancelInspectionForResult: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_results/batch_cancel_inspection';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30220 コピー作成
    copyAndCreate: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_copy_and_create';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },

    // FN_30230 実績確定
    fix: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_fix';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30240 実績出力
    // api待ち

    // FN_30250 ジョブ化
    assignJob: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_assign_job';
      return HttpFactory.createPost(endpoint, params);
    },

    // FN_30250 ジョブ化（シミュレーション）
    simulateAssigningJob: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_simulate_assigning_job';
      return HttpFactory.createPost(endpoint, params);
    },
    // 配送先一括登録
    assignCarrier: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_assign_carrier';
      return HttpFactory.createPost(endpoint, params);
    },

    // FN_30270 出荷指示登録・修正
    upsertForHeader: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_store_or_update';
      return HttpFactory.createPost(endpoint, params);
    },
    // FN_30280  同梱物一括登録
    addNovelty: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_add_novelty';
      return HttpFactory.createPost(endpoint, params);
    },
    // FN_30330 配送業者一括登録
    assignCarrier: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_assign_carrier';
      return HttpFactory.createPost(endpoint, params);
    },
    storeAndDeleteShSmallLotHeader: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_headers/batch_store_and_delete';
      return HttpFactory.createPost(endpoint, params);
    },
    // 一括検品登録
    batchInspectionForHeader: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_force_inspection';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // 一括検品登録
    batchInspectionForResults: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_results/batch_force_inspection';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30100 手動TC仕分け
    reallocateTcStocks: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_results/batch_reallocate_tc_stocks';
      return HttpFactory.createPost(endpoint, params);
    },
    // 自動TC仕分け
    reallocateTcStocksAutomaticallyForHeaders: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/reallocate_tc_stocks_automatically';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // 自動TC仕分け
    reallocateTcStocksAutomaticallyForResults: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_results/reallocate_tc_stocks_automatically';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // 強制TC仕分け
    reallocateTcStocksForciblyForHeaders: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/reallocate_tc_stocks_forcibly';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // 強制TC仕分け
    reallocateTcStocksForciblyForResults: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_results/reallocate_tc_stocks_forcibly';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    addTotalPatternsWithResults: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_add_patterns_with_results';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    addTotalPatternsWithDetails: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_add_patterns_with_details';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30050 自動引当(for header)
    allocateAutomaticallyWithCreatingTcSetForHeader: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_allocate_automatically_with_creating_tc_set';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    // FN_30050 自動引当(for detail)
    allocateAutomaticallyWithCreatingTcSetForDetail: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_details/batch_allocate_automatically_with_creating_tc_set';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    batchExecChangePrcStatus: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_details/batch_change_prc_status';
      return HttpFactory.createPost(endpoint, params);
    },
    batchResetSmallLot: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_reset_small_lot';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    sameShippingAddressSummary: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_same_shipping_address_summary';
      return HttpFactory.createPost(endpoint, {"ids": ids});
    },
    addBoxes: function (ids)
    {
      var endpoint = API_ENDPOINT + '/sh_small_lot_headers/add_box';
      return HttpFactory.createPost(endpoint, {'sh_headers_id': ids});
    },
    // 方面別コード登録
    updateDirectionForHeader: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/batch_update_direction_for_header';
      return HttpFactory.createPost(endpoint, params);
    },
    /**
     * 出荷実績確定時の検品チェック
     */
    shHeadersCheckAllInspectionsNormal: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/check_all_inspections_normal';
      return HttpFactory.createPost(endpoint, params);
    },
    shDetailsCheckAllInspectionsNormal: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_details/check_all_inspections_normal';
      return HttpFactory.createPost(endpoint, params);
    },
    // 複数リソースを同じ値で更新する
    multiUpdate: function (ids, field, value)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/multi_update';
      return HttpFactory.createPut(endpoint, {"ids": ids, "field": field, "value": value});
    },
    // 大森廻漕店 複数リソースを同じ値で更新する
    customMultiUpdate: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_headers/custom_multi_update';
      return HttpFactory.createPut(endpoint, {"data": params});
    },
    /**
     * 商流ラベル for MSL
     */
    publishMaterialLabelForResult: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_results/batch_publish_material_labels';
      return HttpFactory.createPost(endpoint, params);
    },
    // 抽出条件追加・更新
    upsertForFeatures: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_featuresEdits/batch_store_from_features';
      return HttpFactory.createPost(endpoint, params);
    },
    shFeaturesGrouping: function (params)
    {
      var endpoint = API_ENDPOINT + '/sh_featuresEdits/features_grouping';
      endpoint += "?" + Utils.getQueryStr(params);
      return HttpFactory.createGet(endpoint, false);
    },
    // オーダー特性判別を実施
    addShFeatures: function ()
    {
      var endpoint = API_ENDPOINT + '/sh_headers/add_sh_features';
      return HttpFactory.createPost(endpoint);
    }
  }
});

